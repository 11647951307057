<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-12">
                        <form
                            class="form-horizontal form-horizontal-text-right"
                        >
                            <ts-panel class="mb-3">
                                <ts-panel-wrapper>
                                    <disbursement-profile
                                        v-model="model"
                                        :validation="errors"
                                        ref="disbursementProfile"
                                    />
                                </ts-panel-wrapper>
                            </ts-panel>
                            <div class="row">
                                <div class="col-md-3">
                                    <customer-profile v-model="model" />
                                </div>
                                <div class="col-md-9">
                                    <repayment-schedule
                                        ref="repaymentSchedule"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'loan-disbursement' })"
                class="btn-gray"
            >
                {{ $t('cancel') }}</ts-button
            >
            <ts-button
                color="info"
                outline
                :waiting="save_previewing"
                :disabled="waiting"
                @click.prevent="onSavePreview"
            >
                {{ $t('loanDisbursement.savePreviewSchedule') }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="save_previewing"
                @click.prevent="onUpdate"
            >
                {{ $t('update') }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import moment from "moment";
import { isEmpty } from "lodash"
import disbursementProfile from "./components/disbursement-profile.vue";
import customerProfile from "./components/customer-profile.vue";
import repaymentSchedule from "./components/repayment-schedule.vue";

export default {
    name: "loanDisbursementEdit",
    components: {
        disbursementProfile,
        customerProfile,
        repaymentSchedule
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            save_previewing: false,
            errors: new Errors(),
            model: {
                loan_id: null,
                disbursement_date: moment().format("DD-MM-YYYY"),
                customer_id: null,
                voucher_number: null,
                loan_type_id: null,
                currency_id: 1,
                loan_amount: null,
                interest_rate: null,
                repayment_method_id: 4,
                payment_interval: 1,
                first_installment_date: null,
                installment_type_id: null,
                number_of_cycle: null,
                is_skip_sunday: false,
                amount_per_cycle: null,
                allow_pay_off_on_cycle: null,
                co_id: null,
                semi_baloon_effective_cycle: null,
                remarks: null,
                input_source: 'W',
                asset_down_payment_id: null,
            }
        };
    },
    computed: {
        ...mapState('creditOperation/loanDisbursement', ['edit_data']),
        isUpdate () {
            if (!isEmpty(this.edit_data)) {
                return true
            }
            return false
        },
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("creditOperation/loanDisbursement", [
            "getLoanType",
            "getCurrency",
            "getRepaymentMethod",
            "find",
            'getEmployees',
            'getCustomer'
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getLoanType();
            await this.getCurrency();
            await this.getRepaymentMethod();
            await this.$refs.disbursementProfile.loadEmployee();
            await this.$refs.disbursementProfile.loadCustomer();
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: "error", text: error.message });
            });
            this.setEditData();
            this.loading = false;
        },
        getRepaymentSchedule (loan_id) {
            if(loan_id){
                this.$refs.repaymentSchedule.scheduleLoading = true;
                this.find(loan_id)
                    .then(() => {})
                    .catch(error => {
                        this.errors = new Errors(error.errors)
                        this.notice({ type: 'error', text: error.message })
                    })
                    .finally(() => {
                        this.$refs.repaymentSchedule.scheduleLoading = false;
                    })
                }
        },
        onSavePreview() {
            this.errors = new Errors();
            this.save_previewing = true;
            this.$store
                .dispatch("creditOperation/loanDisbursement/update", {
                    id: this.model.loan_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.getRepaymentSchedule(response.resource_id);
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.save_previewing = false;
                });
        },
        onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("creditOperation/loanDisbursement/update", {
                    id: this.model.loan_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    if(response.message_id == 0)
                    {
                        this.clearInput();
                        this.$router.push({ name: "loan-disbursement" });
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            if (!isEmpty(this.edit_data)) {
                await this.getEmployees({
                    search: this.edit_data.co
                        ? this.edit_data.co.employee_name_en
                        : null
                });
                await this.getCustomer({
                    search: this.edit_data.customer
                        ? this.edit_data.customer.customer_name_en
                        : null
                });
                this.model.loan_id = this.edit_data.loan_id;
                this.model.disbursement_date = this.edit_data.disbursement_date;
                this.model.customer_id = this.edit_data.customer_id;
                this.model.voucher_number = this.edit_data.voucher_number;
                this.model.loan_type_id = this.edit_data.loan_type_id;
                this.model.currency_id = this.edit_data.currency_id;
                this.model.loan_amount = this.edit_data.loan_amount;
                this.model.interest_rate = this.edit_data.interest_rate;
                this.model.repayment_method_id = this.edit_data.repayment_method_id;
                this.model.payment_interval = this.edit_data.payment_interval;
                this.model.first_installment_date = this.edit_data.first_installment_date;
                this.model.installment_type_id = this.edit_data.installment_type_id;
                this.model.number_of_cycle = this.edit_data.number_of_cycle;
                this.model.is_skip_sunday = this.edit_data.is_skip_sunday;
                this.model.amount_per_cycle = this.edit_data.amount_per_cycle;
                this.model.allow_pay_off_on_cycle = this.edit_data.allow_pay_off_on_cycle;
                this.model.co_id = this.edit_data.co_id;
                this.model.semi_baloon_effective_cycle = this.edit_data.semi_baloon_effective_cycle;
                this.model.remarks = this.edit_data.remarks;
                this.model.asset_down_payment_id = this.edit_data.asset_down_payment_id;
            }
        },
        clearInput() {
                this.model.disbursement_date = moment().format("DD-MM-YYYY"),
                this.model.customer_id = null,
                this.model.voucher_number = null,
                this.model.loan_type_id = null,
                this.model.currency_id = 1,
                this.model.loan_amount = null,
                this.model.interest_rate = null,
                this.model.repayment_method_id = 4,
                this.model.payment_interval = 1,
                this.model.first_installment_date = null,
                this.model.installment_type_id = null,
                this.model.number_of_cycle = null,
                this.model.is_skip_sunday = false,
                this.model.amount_per_cycle = null,
                this.model.allow_pay_off_on_cycle = null,
                this.model.co_id = null,
                this.model.semi_baloon_effective_cycle = 0,
                this.model.remarks = null
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LOAN DISBURSEMENT",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        this.$store.commit("creditOperation/loanDisbursement/RESET_STATE");
        this.$store.commit("creditOperation/loanDisbursement/CLEAR_RESOURCES");
        next();
    }
};
</script>
